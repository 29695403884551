import MsDyn365, { IImageData } from '@msdyn365-commerce/core';
import  _get from 'lodash/get';
import  _cloneDeep from 'lodash/cloneDeep';
import * as React from 'react';

interface IImageDimension {
    width: string;
    height: string;
}
interface IImageSettings {
    sm: IImageDimension;
    md: IImageDimension;
    lg: IImageDimension;
}
export interface IResponsiveImage {
    desktop: IImageData | undefined;
    tablet: IImageData | undefined;
    mobile: IImageData | undefined;
    defaultImg: IImageData | undefined;
    imageSettingViewports?: IImageSettings | undefined;
}

export interface IResponsiveImageFromSeoData {
    desktop: IImageData | undefined;
    tablet: IImageData | undefined;
    mobile: IImageData | undefined;
    imageSettingViewports?: IImageSettings | undefined;
}

export const getResponsiveFromSeoData = ({ desktop, tablet, mobile }: IResponsiveImageFromSeoData) => {
    const desktopWidth = 1025;
    const tabletWidth = 768;

    const query = {
        desktop: '',
        tablet: '',
        mobile: ''
    };

    // Removing resizing for the time being to discuss with Microsoft
    // if (imageSettingViewports) {
    //     const { sm, md, lg } = imageSettingViewports;
    //     query.desktop = `&w=${lg.width}&h=${lg.height}`;
    //     query.tablet = `&w=${md.width}&h=${md.height}`;
    //     query.mobile = `&w=${sm.width}&h=${sm.height}`;
    // }

    return (
        <picture>
            <>
                {desktop?.src && <source media={`(min-width: ${desktopWidth}px)`} srcSet={`${desktop.src}${query.desktop}`} />}
                {tablet?.src && <source media={`(min-width: ${tabletWidth}px)`} srcSet={`${tablet.src}${query.tablet}`} />}
                {mobile?.src && <source media={`(max-width: ${tabletWidth}px)`} srcSet={`${mobile.src}${query.mobile}`} />}
                <img className='single-category-banner-image' srcSet={desktop?.src} alt={desktop?.altText} />
            </>
        </picture>
    );

};

// Note Responsive Image does not use Msdyn365 Image component
export const getResponsiveImage = ({ desktop, tablet, mobile, defaultImg }: IResponsiveImage): React.ReactNode => {
    // Note this is a temp solution as D365 does not support dynamic images across viewports

    const desktopWidth = 1025;
    const tabletWidth = 768;
    const lazyload = !defaultImg?.imageSettings?.disableLazyLoad;

    const query = {
        desktop: '',
        tablet: '',
        mobile: ''
    };

    // Removing resizing for the time being to discuss with Microsoft
    // if (imageSettingViewports) {
    //     const { sm, md, lg } = imageSettingViewports;
    //     query.desktop = `&w=${lg.width}&h=${lg.height}`;
    //     query.tablet = `&w=${md.width}&h=${md.height}`;
    //     query.mobile = `&w=${sm.width}&h=${sm.height}`;
    // }

    const defaultImgSrc = `${defaultImg?.src}${query.desktop}${
        defaultImg?.imageSettings?.quality ? `&q=${defaultImg.imageSettings.quality}` : ''
    }`;

    return (
        <picture>
            {lazyload ? (
                <>
                    {desktop?.src && <source media={`(min-width: ${desktopWidth}px)`} data-srcset={`${desktop.src}${query.desktop}`} />}
                    {tablet?.src && <source media={`(min-width: ${tabletWidth}px)`} data-srcset={`${tablet.src}${query.tablet}`} />}
                    {mobile?.src && <source media={`(max-width: ${tabletWidth}px)`} data-srcset={`${mobile.src}${query.mobile}`} />}
                    <img
                        src='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
                        data-src={defaultImgSrc}
                        alt={defaultImg?.altText}
                        className='lazyload'
                    />
                </>
            ) : (
                <>
                    {desktop?.src && <source media={`(min-width: ${desktopWidth}px)`} srcSet={`${desktop.src}${query.desktop}`} />}
                    {tablet?.src && <source media={`(min-width: ${tabletWidth}px)`} srcSet={`${tablet.src}${query.tablet}`} />}
                    {mobile?.src && <source media={`(max-width: ${tabletWidth}px)`} srcSet={`${mobile.src}${query.mobile}`} />}
                    <img srcSet={defaultImgSrc} alt={defaultImg?.altText} />
                </>
            )}
        </picture>
    );
};

export interface IDynamicImageProps {
    moduleName: string;
}

export const getDynamicImageSettings = ({ moduleName }: IDynamicImageProps) => {
    // This logic is directly from MsDyn Image Component src builder
    // just applied to get dynamic image settings if they are defined
    const imageSettings = { viewports: undefined };
    const themeSettingsForModule = MsDyn365.themeSettings?.mattressfirm?.modules?.[moduleName];
    if (themeSettingsForModule) {
        const themeSettingsForLayout = themeSettingsForModule.properties.dynamic;
        if (themeSettingsForLayout) {
            const imageViewportProperties = _get(themeSettingsForLayout.properties, ['image'], { properties: imageSettings }).properties;
            // Clone the image viewport properties from theme settings
            // so that the proxy traps do not affect the global themesettings
            imageSettings.viewports = _cloneDeep(imageViewportProperties);
        }
    }

    return imageSettings.viewports;
};
