import { IImageData } from '@msdyn365-commerce/core';
import { ITealiumEventData, ITealiumPromotionData } from '../../definition-extensions/content-block.ext.props.autogenerated';

export const getPromoAnalyticsConfig = (image: IImageData | undefined, link: string | undefined, pagePath: string, paragraphText: string, tealiumPromotion: ITealiumPromotionData | undefined, isCategoryBanner?: boolean, headerText?: string) => {
    // Use Configured Paragraph text as Promotion Name
    let promoName;
    if (isCategoryBanner) {
        promoName = tealiumPromotion?.promotion_name || headerText || image?.title || image?.altText || '';
    } else {
        promoName = tealiumPromotion?.promotion_name || paragraphText;
    }
    // Use Configured Link as Promotion ID
    const promoID = tealiumPromotion?.promotion_id || link || '';
    // Use Configured Image URL as default Creative
    const promoCreative = tealiumPromotion?.promotion_creative_version || image?.title || image?.altText || '';
    const promoPosition = tealiumPromotion?.promotion_position || (isCategoryBanner ? '1' : undefined);

    return {
        eventLabel: `${promoName} from ${pagePath}`,
        promotionName: [promoName],
        promotionId: [promoID],
        promotionCreative: [promoCreative],
        promotionPosition: [promoPosition]
    };
};

export const getAnalyticsConfig = (pagePath: string, heading: string | undefined, imageAriaLabel: string | undefined, tealiumEvent: ITealiumEventData | undefined) => {
    return {
        eventName: tealiumEvent?.event || 'content-block',
        eventCategory: tealiumEvent?.event_category || `${heading ? heading : imageAriaLabel} container`,
        eventType: tealiumEvent?.event_type || '',
        eventAction: tealiumEvent?.event_action || `${heading ? heading : imageAriaLabel} click`,
        eventLabel: tealiumEvent?.event_label || pagePath
    };
};